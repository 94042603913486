// Auth just for storing token
export const AUTH_USER = "AUTH_USER";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_ROLE = "AUTH_ROLE";

// Forgot Password
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const FORGOT_PASSWORD_ASSIGN_USER = "FORGOT_PASSWORD_ASSIGN_USER"
export const RESET_PASSWORD_ASSIGN_OTP = "RESET_PASSWORD_ASSIGN_OTP";
export const CLEAR_ERROR = "CLEAR_ERROR";

// Forgot Password
export const OTP_ERROR = "FORGOT_PASSWORD";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD";

// Register
export const REGISTER = "REGISTER";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const REGISTER_BOTTOM_FORM_ERROR = "REGISTER_BOTTOM_FORM_ERROR";

// Page Access
export const PAGE_ACCESS = "PAGE_ACCESS";
export const PAGE_ACCESS_ERROR = "PAGE_ACCESS_ERROR";

// Video Access
export const VIDEO_ACCESS = "VIDEO_ACCESS";
export const VIDEO_ACCESS_ERROR = "VIDEO_ACCESS_ERROR";

// Register
export const USER_PROFILE = "USER_PROFILE";
export const USER_PROFILE_ERROR = "USER_PROFILE_ERROR";
export const UPDATE_PROFILE_URL = "UPDATE_PROFILE_URL";

export const REGISTER_INFO = "REGISTER_INFO";
export const REGISTER_INFO_ERROR = "REGISTER_INFO_ERROR";
export const REGISTER_INFO_BOTTOM_FORM_ERROR = "REGISTER_INFO_BOTTOM_FORM_ERROR";

// Current logged-in user
export const CURRENT_USER = "CURRENT_USER";

// Update logged-in user
export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER";

export const UPDATE_LOGO = "UPDATE_LOGO";
export const UPDATE_BODY_CSS = "UPDATE_BODY_CSS";

export const REFRESH_TILE_ATTRIBUTE_LIST = "REFRESH_TILE_ATTRIBUTE_LIST";
export const HOME = "HOME";
export const HOME_ERROR = "HOME_ERROR";
export const HOME_DIAGNOSTIC_FILTER_LIST = "HOME_DIAGNOSTIC_FILTER_LIST";
export const HOME_DIAGNOSTIC_ID = "HOME_DIAGNOSTIC_ID";
export const HOME_DIAGNOSTIC_LIST = "HOME_DIAGNOSTIC_LIST";
export const HOME_DISPLAY_TILES = "HOME_DISPLAY_TILES";
export const HOME_DISPLAY_DIAGNOSTICS = "HOME_DISPLAY_DIAGNOSTICS";
export const HOME_ORBIT_ID = "HOME_ORBIT_ID";
export const HOME_ORBIT_LIST = "HOME_ORBIT_LIST";
export const HOME_DEFAULT_ORBIT = "HOME_DEFAULT_ORBIT";
export const INITIAL_TOP_NAV_DISPLAY = "INITIAL_TOP_NAV_DISPLAY";
export const NAVIGATE_TO_HOME_PAGE = "NAVIGATE_TO_HOME_PAGE";
export const TERMS_CONDITIONS_COMPLETED = "TERMS_CONDITIONS_COMPLETED";


export const ORBIT_CLUSTER = "ORBIT_CLUSTER";
export const ORBIT_CLUSTER_ERROR = "ORBIT_CLUSTER_ERROR";

export const TILE_PAGE = "TILE_PAGE";
export const TILE_PAGE_ERROR = "TILE_PAGE_ERROR";

export const ORBIT_LIST_BY_ORGANIZATION = "ORBIT_LIST_BY_ORGANIZATION";
export const ORBIT_ERROR = "ORBIT_ERROR";
export const SET_DEFAULT_ORBIT = "SET_DEFAULT_ORBIT";

export const QR_CODE_URL_REQUEST_ERROR = "QR_CODE_URL_REQUEST_ERROR";

export const BREADCRUMB_ORBIT_CLUSTER_PATH = "BREADCRUMB_ORBIT_CLUSTER_PATH";

export const BREADCRUMB_CLUSTER = 'BREADCRUMB_CLUSTER';
export const BREADCRUMB_TILE = 'BREADCRUMB_TILE';
export const BREADCRUMB_REGULAR_PAGE = 'BREADCRUMB_REGULAR_PAGE';
