import React from "react";
import Container from "react-bootstrap/Container";
import { Nav } from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { HashLink as Link } from "react-router-hash-link";
import YouTube from "react-youtube";

const LivingWellCenter4_2 = () => {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div>
      <div className="w-100 legosNewBuild livingWellCenterHeader">
        <section className="mb-0">
          {/* L6 Background image header bar */}
          <div className="lego l006 legoMargin5">
            <div
              className="backgroundImage"
              style={{
                backgroundImage:
                  "url(" +
                  "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/ca8e76b778079c8652b08017ffcb8674.jpeg" +
                  ")",
              }}
            >
              <div
                className="transparentBarTypeContainer"
                style={{
                  backgroundColor: "#FBAF3F",
                }}
              >
                &nbsp;&nbsp;
              </div>
              <h2 className="l6SubheadWithBackground">
                Setting S.M.A.R.T. Goals
              </h2>
            </div>
          </div>
          {/* End L6 Background image header bar */}
        </section>
        <Container>
          <div className="w-100">
            <div className="col-12 m-auto">
              <div className="whiteBackground floatLeft clearLeft legosNewBuild livingWellCenterHeader">
                <section>
                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin3">
                    <p>
                      At the Living Well Center we recommend building your
                      personal action plan using what is known as S.M.A.R.T.
                      goal-setting. Experience has shown that, by setting
                      S.M.A.R.T. goals, you can increase your chances of
                      success, stay motivated, and track your progress
                      effectively towards achieving your desired outcomes.
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L3 Subhead Upper and Lower */}
                  <div className="lego l003 legoMargin3">
                    <h2
                      className="boldLeadInNewUI"
                      style={{ fontStyle: "italic" }}
                    >
                      S.M.A.R.T. is an acronym that helps you remember to set
                      goals by making them:
                    </h2>
                  </div>
                  {/* End L3 Subhead Upper and Lower */}

                  {/* L83D Icon Color Bar New */}
                  <div className="lego l101 legoMargin1">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/s-icon.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        <span className="boldHeadLine">Specific</span>
                        <br />
                        What exactly do you plan to do?
                        <br /> With whom?  Where? What time of day?
                        <br />
                        How many times per day/week?
                      </p>
                    </div>
                  </div>
                  {/* End L83D Icon Color Bar New */}
                  {/* L83D Icon Color Bar New */}
                  <div className="lego l101 legoMargin1">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/m-icon.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        <span className="boldHeadLine">Measurable</span>
                        <br />
                        How will you measure and track this?
                      </p>
                    </div>
                  </div>
                  {/* End L83D Icon Color Bar New */}
                  {/* L83D Icon Color Bar New */}
                  <div className="lego l101 legoMargin1">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/a-icon.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        <span className="boldHeadLine">Achievabler</span>
                        <br />
                        Are you 70% sure you can accomplish this?
                        <br />
                        If yes, proceed. If no, change your goal to be <br />
                        more achievable.
                      </p>
                    </div>
                  </div>
                  {/* End L83D Icon Color Bar New */}
                  {/* L83D Icon Color Bar New */}
                  <div className="lego l101 legoMargin1">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/r-icon.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        <span className="boldHeadLine">Relevant</span>
                        <br />
                        Does this goal connect with your long-term goal and
                        intention?  Is it important enough to you? 
                      </p>
                    </div>
                  </div>
                  {/* End L83D Icon Color Bar New */}

                  {/* End L83D Icon Color Bar New */}
                  {/* L83D Icon Color Bar New */}
                  <div className="lego l101 legoMargin3">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/t-icon.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        <span className="boldHeadLine">Time-bound</span>
                        <br />
                        When will you assess your progress on this goal?
                      </p>
                    </div>
                  </div>
                  {/* End L83D Icon Color Bar New */}

                  {/* L66 Divider Rule */}
                  <div className="lego l066A legoMargin5">
                    <hr className="horizonalRule" />
                  </div>
                  {/* End L66 Divider Rule */}

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin2">
                    <h2 className="subHeadAllCaps">WE WILL HELP YOU!</h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin3">
                    <p>
                      As part of your early visits, we will help you prepare
                      your own S.M.A.R.T. goals by using a worksheet we will
                      provide. For each category, you can write one to three
                      sentences that summarize your goal in a way that you can
                      envision your action starting right away.
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  <div className="col-12 backToTop">
                    <div className="w-100">
                      <p>
                        <a
                          className="specialUseBackToTop"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          BACK TO TOP{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default LivingWellCenter4_2;
