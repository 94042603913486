import React from "react";
import Container from "react-bootstrap/Container";
import { Nav } from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { HashLink as Link } from "react-router-hash-link";
import YouTube from "react-youtube";

const LivingWellCenter6_4 = () => {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div>
      <div className="w-100 legosNewBuild livingWellCenterHeader">
        <section className="mb-0">
          {/* L6 Background image header bar */}
          <div className="lego l006 legoMargin5">
            <div
              className="backgroundImage"
              style={{
                backgroundImage:
                  "url(" +
                  "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/7a3f944e2d4119969d65663c9d9a5ecb.jpeg" +
                  ")",
              }}
            >
              <div
                className="transparentBarTypeContainer"
                style={{
                  backgroundColor: "#FA4E36",
                }}
              >
                &nbsp;&nbsp;
              </div>
              <h2 className="l6SubheadWithBackground">
                Tai Chi & Yoga Programs
              </h2>
            </div>
          </div>
          {/* End L6 Background image header bar */}
        </section>
        <Container>
          <div className="w-100">
            <div className="col-12 m-auto">
              <div className="whiteBackground floatLeft clearLeft legosNewBuild livingWellCenterHeader">
                <section>
                  {/* L12A Head, Lead In Photo Larger with body copy */}

                  <div className="lego l012A">
                    {/* L3 Subhead Upper and Lower */}
                    <div className="lego l003">
                      <h2 className="subHeadUpperAndLowerCase">TAI CHI</h2>
                    </div>
                    {/* End L3 Subhead Upper and Lower */}
                    <div className="contentContainer">
                      <div className="imgContainer">
                        <img
                          className=""
                          alt="Adults of Mulitple Ethnicities doing Tai Chi"
                          src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/2f23ab9a6de41bfefce057874fd73722.png"
                        />
                      </div>
                      <div className="copyContainer">
                        {/* L14 Paragraph */}
                        <div className="lego l00paragraph legoMargin3">
                          <p>
                            Tai Chi is a mindfulness approach that employs slow
                            and fast intentional movements, breathing
                            techniques, and a rich array of cognitive tools to
                            relax, center and strengthen the body and mind. Tai
                            Chi is practiced for health, personal development,
                            and self-defense.
                          </p>
                          <p className="legoMargin3">
                            <span className="bold italic">
                              Medical research supports that Tai Chi and Qigong
                              can improve balance, musculoskeletal strength and
                              flexibility, cardio-respiratory health, cognitive
                              function, immune system response, and emotional
                              well-being.
                            </span>
                          </p>
                          <p>
                            Click to{" "}
                            <a
                              href="https://www.ortho.wustl.edu/content/Patient-Care/8422/Services/Living-Well-Center/Tai-Chi-Program.aspx"
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              Learn More
                            </a>
                          </p>
                        </div>
                        {/* End L14 Paragraph */}
                      </div>
                    </div>
                  </div>
                  {/* End L12A Head, Lead In Photo Larger with body copy */}

                  {/* L12A Head, Lead In Photo Larger with body copy */}
                  <div className="lego l012A">
                    {/* L3 Subhead Upper and Lower */}
                    <div className="lego l003">
                      <h2 className="subHeadUpperAndLowerCase">YOGA</h2>
                    </div>
                    {/* End L3 Subhead Upper and Lower */}
                    <div className="contentContainer">
                      <div className="imgContainer">
                        <img
                          className=""
                          alt="Adults of Mulitple Ethnicities doing Tai Chi"
                          src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/232e3e389f795a281b854e30b831a697.png"
                        />
                      </div>
                      <div className="copyContainer">
                        {/* L14 Paragraph */}
                        <div className="lego l00paragraph legoMargin3">
                          <p>
                            The initial series of six classes will provide
                            building blocks to inspire and assist students
                            establishing a home practice. The practice of yoga
                            means “to yoke” the union of two opposites to create
                            equanimity and balance.
                          </p>
                          <p className="legoMargin2">
                            When we  
                            <span className="bold italic">
                              move our bodies with breath-centric awareness
                            </span>{" "}
                            to calm the anxious mind, it has a profound effect
                            on our nervous system, reducing stress, improving
                            digestion, better sleep and connecting to ourselves.
                          </p>
                          <p className="legoMargin2">
                            This class will feature{" "}
                            <span className="bold italic">
                              breathing practices, meditation and asana
                            </span>{" "}
                            (standing postures). The sequencing can blend
                            various elements that have been known to improve our
                            over-worked, stressed out state of mind, build
                            strength and tone our foundation from feet to hips.
                          </p>
                          <p className="legoMargin2">
                            We will explore extensions and contractions to help
                            improve posture in the spine, and elongate tight
                            muscles and gain flexibility along with stability
                            while exploring ways to develop our practice. We
                            will work with the diaphragm, the main muscle of
                            breathing, to assist in refining breath work—vital
                            for all bodily functions—energy, restoring
                            equanimity and toning the core for spinal stability.
                          </p>
                          <p className="legoMargin2">
                            <span className="bold italic">
                              This class is open to anyone of all ages and
                              athletic ability.
                            </span>{" "}
                          </p>
                          <p>
                            Click to{" "}
                            <a
                              href=""
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              Learn More
                            </a>
                          </p>
                        </div>
                        {/* End L14 Paragraph */}
                      </div>
                    </div>
                  </div>
                  {/* End L12A Head, Lead In Photo Larger with body copy */}
                  <div className="col-12 backToTop">
                    <div className="w-100">
                      <p>
                        <a
                          className="specialUseBackToTop"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          BACK TO TOP{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default LivingWellCenter6_4;
