import React from "react";
import Container from "react-bootstrap/Container";
import { Nav } from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { HashLink as Link } from "react-router-hash-link";
import YouTube from "react-youtube";

const LivingWellCenter4_4 = () => {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div>
      <div className="w-100 legosNewBuild livingWellCenterHeader">
        <section className="mb-0">
          {/* L6 Background image header bar */}
          <div className="lego l006 legoMargin5">
            <div
              className="backgroundImage"
              style={{
                backgroundImage:
                  "url(" +
                  "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/f0081153ee53760d47076956463551ac.jpeg" +
                  ")",
              }}
            >
              <div
                className="transparentBarTypeContainer"
                style={{
                  backgroundColor: "#FBAF3F",
                }}
              >
                &nbsp;&nbsp;
              </div>
              <h2 className="l6SubheadWithBackground">Insurance and Finance</h2>
            </div>
          </div>
          {/* End L6 Background image header bar */}
          <div className="col-12 m-auto"></div>
        </section>

        <Container>
          <div className="w-100">
            <div className="col-12 m-auto">
              <div className="w-100 legosNewBuild livingWellCenterHeader">
                <section>
                  {/* L12B? Content 1 side 70% width */}
                  <div className="lego l012B p-0">
                    <div className="contentLeftSide">
                      {/* L11 Image */}
                      <div
                        className="lego l011 legoMargin3"
                        style={{ justifyContent: "flex-end" }}
                      >
                        <img
                          className="imageW100 noBorderRadius imgDimensions"
                          src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/3037bd1d9a309efaa91a903e78243582.jpeg"
                        />
                      </div>
                      {/* End L11 Image */}
                      
                    </div>
                    <div className="contentRightSide">
                      {/* L14 Paragraph */}
                      <div className="lego l00paragraph legoMargin3">
                        <p className="legoMargin3">
                          Our mission is to meet the needs of those we serve. To
                          help, we have established a policy of communicating
                          financial expectations to our patients. You can review
                          our billing and payment policies and information on
                          payment options for self-pay patients, patients with
                          insurance, as well as access a current Payment and
                          Policies brochure {" "}
                          {/* L14D Paragraph External Link */}
                          <a
                            className="blueLinkfont22"
                            href="https://www.ortho.wustl.edu/content/Patient-Care/8167/Appointment-Information/Billing-and-Payment-Information.aspx"
                            rel="noreferrer noopener"
                            target="_blank"
                          >
                            here.
                          </a>
                          {/* End L14D Paragraph External Link */}
                        </p>
                        <p>
                          <span className="boldText2">
                            Determining if your Living Well Center services are
                            covered.
                          </span>
                          <br />
                          Washington University Orthopedics participates
                          with most medical insurance plans.
                        </p>
                        <p className="legoMargin2">
                          However, private or company insurance plans and plan
                          networks change frequently. Please verify coverage
                          before your appointment. For an updated listing of
                          accepted health insurance plans and networks, please
                          visit the 
                          {/* L14D Paragraph External Link */}
                          <a
                            className="blueLinkfont22"
                            href="https://physicians.wustl.edu/for-patients/health-insurance/"
                            rel="noreferrer noopener"
                            target="_blank"
                          >
                            Washington University Physicians website.
                          </a>
                          {/* End L14D Paragraph External Link */}
                        </p>
                      </div>
                      {/* End L14 Paragraph */}
                      {/* L84 Block */}

                      <div className="lego l084A legoMargin5">
                        {/* L82? Content 2 sides 50% width */}

                        <div className="contentLeftSide">
                          {/* L11 Image */}
                          <div className="lego l011 legoMargin3">
                            <img
                              className="imageW100 noBorderRadius maxHeight400"
                              src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/6074e61c93703e887a2eaf4b20039c6a.jpeg"
                            />
                          </div>
                          {/* End L11 Image */}
                        </div>
                        <div className="contentRightSide">
                          <div className="lego l00paragraph legoMargin1">
                            <p>
                              <span className="boldText">
                                Living Well Center Services and Payment
                                Guidelines
                              </span>
                            </p>
                            <p>
                              You can review insurance coverage guidelines and
                              costs for our{" "}
                              <span className="boldColoredText">
                                Living Well Center Services here.
                              </span>{" "}
                              <span className="coloredText">
                                Link opens PDF “LWC handout Service and Payment
                                Guidelines”
                              </span>
                            </p>
                          </div>
                        </div>
                        {/* End L82? Content 2 sides 50% width */}
                      </div>
                      {/* End L84 Block */}
                    </div>
                  </div>

                  {/* End L12B? Content 1 side 70% width */}

                  <div className="col-12 backToTop">
                    <div className="w-100">
                      <p>
                        <a
                          className="specialUseBackToTop"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          BACK TO TOP{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default LivingWellCenter4_4;
