import React, {useEffect, useState, useRef} from 'react';
import { Link, useLocation } from 'react-router-dom';
import {orbitById} from "../../actions/orbitById";
import {setBreadcrumbOrbitClusterPath} from'../../../src/actions/breadCrumb.js';
import {useDispatch, useSelector} from "react-redux";
import {orbitClusterById} from "../../actions/orbitClusterById";
import {setTermsConditionsCompleted} from "../../actions/home";
import orbitCluster from "../orbitCluster/index";
import {BREADCRUMB_CLUSTER, BREADCRUMB_REGULAR_PAGE, BREADCRUMB_TILE} from "../../actions/types";


const Breadcrumb = (props) => {
    let pathArray = useRef([]);
    const [orbitName, setOrbitName] = useState('');
    const [pathNames, setPathNames] = useState([]);
    const [orbitClusterName, setOrbitClusterName] = useState('');
    const location = useLocation();
    const dispatch = useDispatch();
    const regex = /^[a-z,0-9,-]{36,36}$/;
    const {
        orbitClusterPath
    } = useSelector(state => state.breadCrumb);

    const clearNames = (e) => {
        e.preventDefault();
        setOrbitName(null);
        setOrbitClusterName(null);
    }

    const pruneClusterPath = (clusterArray, name, clusterType) => {
        const currentLocation = location.pathname.split('/').filter((x) => x).filter((x) => x !== 'cluster');

        if (currentLocation && currentLocation.length > 0) {
            const index = clusterArray.findIndex((path) => path.path === currentLocation[0]);
            const hasTile = clusterArray.findIndex((path => path.clusterType === BREADCRUMB_TILE));

            if (clusterArray.length > 1) {
                if (clusterType === BREADCRUMB_CLUSTER && index >= 0) {
                    clusterArray.splice(index + 1);
                } else if (index === -1 && hasTile && clusterType === BREADCRUMB_TILE) {
                    clusterArray.splice(clusterArray.length - 1);
                    clusterArray.push({
                        name,
                        path: currentLocation[0],
                        clusterType
                    });
                } else if (clusterType === BREADCRUMB_REGULAR_PAGE) {
                    clusterArray.splice(0);
                    clusterArray.push({
                        name,
                        path: currentLocation[0],
                        clusterType
                    });
                }
            } else {

                clusterArray.push({
                    name,
                    path: currentLocation[0],
                    clusterType
                });
            }
        } else {
            clusterArray.splice(0);
        }

        return clusterArray;
    }

    useEffect(async () => {
        pathArray = location.pathname.split('/').filter((x) => x);
        setPathNames(location.pathname.split('/').filter((x) => x));
        const title = document.getElementsByClassName('tileTitleHeader');
        if (pathArray.includes('cluster') && regex.test(pathArray[pathArray.length - 1])) {
            const orb = await orbitClusterById({orbitClusterId: pathArray[pathArray.length - 1]}, async (data) => {
                const prunedValue = pruneClusterPath(orbitClusterPath, data.name ? data.name : pathArray[pathArray.length - 1], BREADCRUMB_CLUSTER);
                dispatch(setBreadcrumbOrbitClusterPath({
                    orbitClusterPath: prunedValue
                }));
                setPathNames(prunedValue);
            });
            await dispatch(orb);
        } else if (title && title.length > 0 && title[0].innerText.length > 0) {
            const prunedValue = pruneClusterPath(orbitClusterPath, title[0].innerText, BREADCRUMB_TILE);
            dispatch(setBreadcrumbOrbitClusterPath({
                orbitClusterPath: prunedValue
            }));
            setPathNames(prunedValue);
        } else {
            const prunedValue = pruneClusterPath(orbitClusterPath, pathArray[pathArray.length - 1], BREADCRUMB_REGULAR_PAGE);
            dispatch(setBreadcrumbOrbitClusterPath({
                orbitClusterPath: prunedValue
            }));
            setPathNames(prunedValue);
        }
    }, [location]);

    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item">
                    <a href="/" onclick={clearNames}>Home</a>
                </li>
                {pathNames.map((path, index) => {
                    const routeTo = `/${path.cluster === true ? 'cluster/' : ''}${path.path}`;
                    //const routeTo = `/${pathNames.slice(0, index + 1).join('/')}`;
                    const isLast = index === pathNames.length - 1;
                    return isLast ? (
                        <li key={path.name} className="breadcrumb-item active current-page" aria-current="page">
                            {path.name}
                        </li>
                    ) : (
                        <li key={path.name} className="breadcrumb-item">
                            <a href={path.clusterType === BREADCRUMB_CLUSTER ? '/cluster' + routeTo : routeTo}>{path.name}</a>
                        </li>
                    );
                })}
            </ol>
        </nav>
    );
};

export default Breadcrumb;
