import React from "react";
import Container from "react-bootstrap/Container";
import { Nav } from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { HashLink as Link } from "react-router-hash-link";
import YouTube from "react-youtube";

const LivingWellCenter6_3 = () => {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div>
      <div className="w-100 legosNewBuild livingWellCenterHeader">
        <section className="mb-0">
          {/* L6 Background image header bar */}
          <div className="lego l006 legoMargin5">
            <div
              className="backgroundImage"
              style={{
                backgroundImage:
                  "url(" +
                  "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/b6ac0ce7cf19be01d6341f0581155386.jpeg" +
                  ")",
              }}
            >
              <div
                className="transparentBarTypeContainer"
                style={{
                  backgroundColor: "#FA4E36",
                }}
              >
                &nbsp;&nbsp;
              </div>
              <h2 className="l6SubheadWithBackground">
                Movement & Exercise Program
              </h2>
            </div>
          </div>
          {/* End L6 Background image header bar */}
        </section>
        <Container>
          <div className="w-100">
            <div className="col-12 m-auto">
              <div className="whiteBackground floatLeft clearLeft legosNewBuild livingWellCenterHeader">
                <section>
                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin3">
                    <p>
                      Movement and exercise are vital to maintaining a healthy
                      lifestyle. Exercise has been shown to improve
                      cardiovascular, bone, and mental health. Increasing your
                      daily movement lowers your risk of heart disease, stroke,
                      and the development of certain cancers. If you would like
                      get a jumpstart on your fitness goals or just need a
                      refresher following an illness or injury, please join us
                      for this four session program highlighting the four
                      primary types of exercise.
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L85 Color Bar Top Content Block */}
                  <div className="lego l085">
                    <div
                      className="headerColorBar"
                      style={{ backgroundColor: "#FA4E36" }}
                    >
                      <h3>Program Details:</h3>
                    </div>
                    <div className="contentContainer">
                      <p className="legoMargin1">
                        This program is a part of our 
                        <a
                          className="blueLinkfont18"
                          href="https://www.ortho.wustl.edu/content/Patient-Care/8431/Services/Living-Well-Center/Shared-Medical-Appointments.aspx"
                          rel="noreferrer noopener"
                          target="_blank"
                        >
                          shared medical appointment
                        </a>{" "}
                        series and is held in a group format, which is helpful
                        for goal setting and accountability. The sessions will
                        involve educational content, group exercise activity,
                        and discussion with goal setting. 
                      </p>
                      <p className="legoMargin1">
                        <span className="boldItalic">Classes:</span>  Each class
                        session is 1.5 hours long and held every other week.
                        Each class includes educational information, group
                        discussion, exercise demonstration and activity. {" "} <a
                          className="blueLinkfont18"
                          href="https://www.ortho.wustl.edu/Calendar.aspx"
                          rel="noreferrer noopener"
                          target="_blank"
                        >
                          View
                          our events calendar here for upcoming sessions.
                        </a>{" "} 
                      </p>
                      <p className="legoMargin3">
                        <span className="boldItalic">Format:</span>
                         Virtually and in-person
                      </p>
                      <p className="legoMargin3">
                        <span className="bold">
                          Who can participate in this program?{" "}
                        </span>
                        <br />
                        Participants must have an initial assessment at the
                        WashU Living Well Center® prior to the first movement
                        and exercise session. Each session will provide
                        modifications to meet each participant where they are
                        when it comes to exercise. Ready to make changes to your
                        lifestyle right now? Join our Movement and Exercise
                        Program today! {" "}
                      </p>
                    </div>
                  </div>
                  {/* End L85 Color Bar Top Content Block */}

                  <div className="col-12 backToTop">
                    <div className="w-100">
                      <p>
                        <a
                          className="specialUseBackToTop"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          BACK TO TOP{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default LivingWellCenter6_3;
