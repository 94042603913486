import React, {useEffect, useState} from "react";
import {
    ONE, TWO, THREE, FOUR, FIVE, SIX, SEVEN, EIGHT, NINE, TEN, ELEVEN, TWELVE,
    THIRTEEN, FOURTEEN, TWENTY, TWENTY_ONE, TWENTY_TWO, TWENTY_THREE, TWENTY_FOUR,
    TWENTY_FIVE, CLUSTER_URL, TILE_PAGE_URL
} from './constants.js'
import {userProfile} from "../../actions/userProfile.js";
import {useDispatch} from "react-redux";
import t_002 from '../../images/t_002_icon.svg';
import tile_icon_template from "../../images/_tile-icon-template.svg";
import t_006 from "../../images/t_006-profileImage.svg";
import parse from "html-react-parser";

const Tile = ({
                  rows = 1,
                  cols = 1,
                  tileType = ONE,
                  useAsIndividual = false,
                  useInChain = false,
                  useOuterContainer = true,
                  outerContainerCss,
                  useLeft = false,
                  useRight = false,
                  addHoverToTile = false,
                  tileClickable,
                  attributes: {
                      lineOneText = '',
                      lineTwoText = '',
                      imageUrl,
                      backgroundCss = [],
                      tileLevelCss = [],
                      numberOfItems,
                      linkUrl,
                      childClusterId,
                      tilePageId,
                      displayProfileImage,
                      profileUrl,
                      targetType,
                      addChevron
                  }
              }) => {
    const dispatch = useDispatch();
    //let cardRef = useRef(null);

    let tCombination = 't_0' + (tileType < 10 ? '0' + tileType : tileType);
    let tCombinationRowCol = '';
    let widthColumn = 'width' + cols + 'Column';
    let heightColumn = 'Height' + rows + 'RowTall';
    let background = calculateBackgroundClass({tileType});
    let styleElement = calculateStyleElement({tileType, imageUrl});
    let bootstrapClass = [];

    if (backgroundCss && backgroundCss.length > 0 && tileType !== TWENTY_FIVE && tileType !== THIRTEEN)
        background = background.concat(backgroundCss);

    if (tileLevelCss && tileLevelCss.length > 0)
        background = background.concat(tileLevelCss);

    if (rows > 1 || cols > 1) {
        tCombinationRowCol = tCombination + '_' + cols + 'x' + rows;
    }

    switch (cols) {
        case 1:
            bootstrapClass.push('col-4 paddingLR5 hvr-grow');
            bootstrapClass.push('col-4 paddingLR5 hvr-grow');
            bootstrapClass.push('col-4 paddingLR5 hvr-grow');
            break;
        case 2:
            bootstrapClass.push('col-2 paddingLR5 hvr-grow');
            bootstrapClass.push('col-8 paddingLR5 hvr-grow');
            bootstrapClass.push('col-2 paddingLR5 hvr-grow');
            break;
        default:
            bootstrapClass.push('paddingLR5');
            bootstrapClass.push('col-12 paddingLR5 hvr-grow');
            bootstrapClass.push('paddingLR5');
            break;
    }

    let cssStyles = calculateCssStyles({
        widthColumn,
        heightColumn,
        background,
        tCombination,
        tCombinationRowCol,
        addHoverToTile
    });

    useEffect(async () => {
    }, []);

    return (
        constructOuterContainer({
            tileType,
            cssStyles,
            styleElement,
            lineOneText,
            lineTwoText,
            numberOfItems,
            imageUrl,
            linkUrl,
            tilePageId,
            childClusterId,
            bootstrapClass,
            outerContainerCss,
            cols,
            useAsIndividual,
            useInChain,
            useOuterContainer,
            useLeft,
            useRight,
            displayProfileImage,
            profileUrl,
            targetType,
            backgroundCss,
            addChevron,
            tileClickable
        })
    );
};

const calculateCssStyles = ({
                                widthColumn,
                                heightColumn,
                                background,
                                tCombination,
                                tCombinationRowCol,
                                tileType,
                                addHoverToTile
                            }) => {
    let cssArray = ['tile'];

    if (widthColumn)
        cssArray.push(widthColumn);

    if (heightColumn)
        cssArray.push(heightColumn);

    if (tCombination)
        cssArray.push(tCombination);

    if (tCombinationRowCol)
        cssArray.push(tCombinationRowCol);

    if (tileType)
        cssArray.push(tileType);

    if (addHoverToTile)
        cssArray.push('hvr-grow');

    if (background && background.length > 0)
        cssArray = cssArray.concat(background);


    return cssArray;
}

const calculateStyleElement = ({
                                   tileType,
                                   imageUrl
                               }) => {

    switch (tileType) {
        case ELEVEN:
            return !imageUrl || imageUrl.length === 0 ? {
                backgroundImage: `url("../images/tile_11_generic_bkgd.jpg")`,
                backgroundPosition: "50% 40%",
                backgroundRepeat: "no-repeat"
            } : {backgroundImage: `url("${imageUrl}")`, backgroundPosition: "50% 40%", backgroundRepeat: "no-repeat"};
        case TWELVE:
            return !imageUrl || imageUrl.length === 0 ? {
                backgroundImage: `url("../images/tile_12_generic_bkgd.jpg")`,
                backgroundPosition: "50% 40%",
                backgroundRepeat: "no-repeat"
            } : {backgroundImage: `url("${imageUrl}")`, backgroundPosition: "50% 40%", backgroundRepeat: "no-repeat"};
        case THIRTEEN:
            return !imageUrl || imageUrl.length === 0 ? {
                backgroundImage: `url("../images/nurse_patient_bedside.jpg")`,
                backgroundPosition: "30% 30%",
                backgroundRepeat: "no-repeat"
            } : {backgroundImage: `url("${imageUrl}")`, backgroundPosition: "30% 30%", backgroundRepeat: "no-repeat"};
        case FOURTEEN:
            return !imageUrl || imageUrl.length === 0 ? {
                backgroundImage: `url("../images/Nutrition_Salad_overhead.jpg")`,
                backgroundPosition: "30% 30%",
                backgroundRepeat: "no-repeat"
            } : {backgroundImage: `url("${imageUrl}")`, backgroundPosition: "30% 30%", backgroundRepeat: "no-repeat"};
        case TWENTY_FOUR:
            return !imageUrl || imageUrl.length === 0 ? {
                backgroundImage: `url("../images/tile_24_generic_bkgd.jpg")`,
                backgroundPosition: "0 -10px",
                backgroundRepeat: "no-repeat"
            } : {backgroundImage: `url("${imageUrl}")`, backgroundPosition: "0 -10px", backgroundRepeat: "no-repeat"};
        case TWENTY_FIVE:
            return !imageUrl || imageUrl.length === 0 ? {
                backgroundImage: `url("../images/tile_24_generic_bkgd.jpg")`,
                backgroundPosition: "0 -10px",
                backgroundRepeat: "no-repeat"
            } : {backgroundImage: `url("${imageUrl}")`, backgroundPosition: "50% 30%", backgroundRepeat: "no-repeat"};
        default:
            return {};
    }
}

const calculateBackgroundClass = ({
                                      tileType
                                  }) => {
    let classList = [];

    switch (tileType) {
        case ONE:
        case THREE:
        case FOUR:
        case FIVE:
        case SIX:
        case SEVEN:
        case EIGHT:
        case NINE:
        case TEN:
        case TWENTY_FIVE:
            //classList.push('blueBackground');
            break;
        case TWO:
            //classList.push('tileLightBlueBackground');
            break;
        case ELEVEN:
        case TWELVE:
        case TWENTY_FOUR:
            classList.push('tileWithBottomBanner');
            classList.push('blueBorder');
            break;
        case THIRTEEN:
        case FOURTEEN:
            classList.push('tileWithCenterBanner');
            //classList.push('blueBackground');
            break;
        case TWENTY:
            classList.push('tileWithTopBanner')
            classList.push('iconTextTile')
            classList.push('blueBorder')
            //classList.push('whiteBackgroundTileBackground')
            break;
        case TWENTY_ONE:
        case TWENTY_TWO:
        case TWENTY_THREE:
            classList.push('tileCenter');
            break;
        default:
            classList = [];
            break;
    }

    return classList;
}

const constructHtmlBody = ({
   tileType,
   cssStyles,
   styleElement,
   lineOneText,
   lineTwoText,
   numberOfItems,
   imageUrl,
   displayProfileImage,
   profileUrl,
   backgroundCss,
   addChevron
}) => {

    switch (tileType) {
        case ONE:
            return (
                <div className={cssStyles.join(' ')} style={styleElement}>
                    <div className="contentCenter">
                        <div className="tileJustText">
                            {lineOneText && lineOneText.length > 0 ?
                                <h3 className="text-center whiteColor">{parse(lineOneText)} {(addChevron === undefined || addChevron) && lineOneText.length && (!lineTwoText || lineTwoText.length === 0) ?
                                    <i className="fa fa-angle-right" aria-hidden="true"></i> : ''}</h3> : ''}
                            {lineTwoText && lineTwoText.length > 0 ?
                                <h3 className="text-center whiteColor">{parse(lineTwoText)} {(addChevron === undefined || addChevron) && lineTwoText && lineTwoText.length > 0 ?
                                    <i className="fa fa-angle-right" aria-hidden="true"></i> : ''}</h3> : ''}
                        </div>
                    </div>
                </div>
            )
        case TWO:
            return (
                <div className={cssStyles.join(' ')} style={styleElement}>
                    <div className="contentCenter">
                        <div className="tileJustText">
                            {lineOneText && lineOneText.length > 0 ?
                                <h3 className="text-center whiteColor">{parse(lineOneText)} {(addChevron === undefined || addChevron) && lineOneText.length && (!lineTwoText || lineTwoText.length === 0) ?
                                    <i className="fa fa-angle-right" aria-hidden="true"></i> : ''}</h3> : ''}
                            {lineTwoText && lineTwoText.length > 0 ?
                                <h3 className="text-center whiteColor">{parse(lineTwoText)} {(addChevron === undefined || addChevron) && lineTwoText && lineTwoText.length > 0 ?
                                    <i className="fa fa-angle-right" aria-hidden="true"></i> : ''}</h3> : ''}
                        </div>
                    </div>
                    <div className="iconBottomRight">
                        <img className="rightDirectionalArrow" src={imageUrl && imageUrl.length > 0 ? imageUrl : t_002}/>
                    </div>
                </div>
            )
        case THREE:
            return (
                <div className={cssStyles.join(' ')} style={styleElement}>
                    <div className="contentCenter">
                        <div className="centerCircle">
                            <img alt="" className="img-responsive img-circle"
                                 src={imageUrl && imageUrl.length > 0 ? imageUrl : tile_icon_template}/>
                        </div>
                        <div className="textBelowIcon">
                            {lineOneText && lineOneText.length > 0 ?
                                <h3 className="text-center whiteColor">{parse(lineOneText)} {(addChevron === undefined || addChevron) && lineOneText && lineOneText.length > 0 ?
                                    <i className="fa fa-angle-right" aria-hidden="true"></i> : ''}</h3> : ''}
                        </div>
                    </div>
                </div>
            )
        case FOUR:
            return (
                <div className={cssStyles.join(' ')} style={styleElement}>
                    <div className="contentCenter">
                        <div className="centerCircle">
                            <img alt="" className="img-responsive img-circle"
                                 src={imageUrl && imageUrl.length > 0 ? imageUrl : tile_icon_template}/>
                        </div>
                        <div className="textBelowIcon">
                            {lineOneText && lineOneText.length > 0 ?
                                <h3 className="text-center whiteColor">{parse(lineOneText)} {(addChevron === undefined || addChevron) && lineOneText.length && (!lineTwoText || lineTwoText.length === 0) ?
                                    <i className="fa fa-angle-right" aria-hidden="true"></i> : ''}</h3> : ''}
                            {lineTwoText && lineTwoText.length > 0 ?
                                <h3 className="text-center whiteColor">{parse(lineTwoText)} {(addChevron === undefined || addChevron) && lineTwoText && lineTwoText.length > 0 ?
                                    <i className="fa fa-angle-right" aria-hidden="true"></i> : ''}</h3> : ''}
                        </div>
                    </div>
                </div>
            )
        case FIVE:
            return (
                <div className={cssStyles.join(' ')} style={styleElement}>
                    <div className="contentCenter">
                        <div className="centerCircle">
                            <img alt="" className="img-responsive img-circle" src={imageUrl && imageUrl.length > 0 ? imageUrl : t_006}/>
                        </div>
                        <div className="textBelowIcon">
                            {lineOneText && lineOneText.length > 0 ?
                                <h3 className="text-center whiteColor">{parse(lineOneText)} {(addChevron === undefined || addChevron) && lineOneText && lineOneText.length > 0 ?
                                    <i className="fa fa-angle-right" aria-hidden="true"></i> : ''}</h3> : ''}
                        </div>
                    </div>
                </div>
            )
        case SIX:
            return (
                <div className={cssStyles.join(' ')} style={styleElement}>
                    <div className="contentCenter">
                        <div className="centerCircle">
                            <img alt="" className="img-responsive img-circle" src={imageUrl && imageUrl.length > 0 ? imageUrl : t_006}/>
                        </div>
                        <div className="textBelowIcon">
                            {lineOneText && lineOneText.length > 0 ?
                                <h3 className="text-center whiteColor">{parse(lineOneText)} {(addChevron === undefined || addChevron) && lineOneText.length && (!lineTwoText || lineTwoText.length === 0) ?
                                    <i className="fa fa-angle-right" aria-hidden="true"></i> : ''}</h3> : ''}
                            {lineTwoText && lineTwoText.length > 0 ?
                                <h3 className="text-center whiteColor">{parse(lineTwoText)} {(addChevron === undefined || addChevron) && lineTwoText && lineTwoText.length > 0 ?
                                    <i className="fa fa-angle-right" aria-hidden="true"></i> : ''}</h3> : ''}
                        </div>
                    </div>
                </div>
            )
        case SEVEN:
            return (
                <div className={cssStyles.join(' ')} style={styleElement}>
                    <div className="contentCenter">
                        <div className="tileJustText">
                            <div className="h3Container">
                                {lineOneText && lineOneText.length > 0 ?
                                    <h3 className="text-center whiteColor">{parse(lineOneText)} {(addChevron === undefined || addChevron) && lineOneText && lineOneText.length > 0 ?
                                        <i className="fa fa-angle-right" aria-hidden="true"></i> : ''}</h3> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            )
        case EIGHT:
            return (
                <div className={cssStyles.join(' ')} style={styleElement}>
                    <div className="contentCenter">
                        <div className="tileJustText">
                            <div className="h3Container">
                                {lineOneText && lineOneText.length > 0 ?
                                    <h3 className="text-center whiteColor">{parse(lineOneText)}<br/>{parse(lineTwoText)} {(addChevron === undefined || addChevron) && ((lineOneText && lineOneText.length > 0) || (lineTwoText && lineTwoText.length > 0)) ?
                                        <i className="fa fa-angle-right" aria-hidden="true"></i> : ''}</h3> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            )
        case NINE:
            return (
                <div className={cssStyles.join(' ')} style={styleElement}>
                    <div className="contentCenter">
                        <div className="tileJustText">
                            <div className="textBorderTopBorderBottom">
                                {lineOneText && lineOneText.length > 0 ?
                                    <h3 className="text-center whiteColor">{parse(lineOneText)} {(addChevron === undefined || addChevron) && lineOneText && lineOneText.length > 0 ?
                                        <i className="fa fa-angle-right" aria-hidden="true"></i> : ''}</h3> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            )
        case TEN:
            return (
                <div className={cssStyles.join(' ').replace(',', ' ')} style={styleElement}>
                    <div className="contentCenter">
                        <div className="tileJustText">
                            <div className="textBorderTopBorderBottom">
                                {lineOneText && lineOneText.length > 0 ?
                                    <h3 className="text-center whiteColor">{parse(lineOneText)}<br/>{parse(lineTwoText)} {(addChevron === undefined || addChevron) && ((lineOneText && lineOneText.length > 0) || (lineTwoText && lineTwoText.length > 0)) ?
                                        <i className="fa fa-angle-right" aria-hidden="true"></i> : ''}</h3> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            )
        case ELEVEN:
            return (
                <div className={cssStyles.join(' ').replace(',', ' ')} style={styleElement}>
                    <div className="bottomTextTileBanner text-center whiteBackgroundTile">
                        <div className="h3Container text-center">
                            {lineOneText && lineOneText.length > 0 ?
                                <h3>{parse(lineOneText)} {addChevron && lineOneText && lineOneText.length > 0 ?
                                    <i className="fa fa-angle-right" aria-hidden="true"></i> : ''}</h3> : ''}
                        </div>
                    </div>
                </div>
            )
        case TWELVE:
            return (
                <div className={cssStyles.join(' ').replace(',', ' ')} style={styleElement}>
                    <div className="bottomTextTileBanner text-center blueBackground whiteColor">
                        <div className="h3Container text-center">
                            {lineOneText && lineOneText.length > 0 ?
                                <h3>{parse(lineOneText)} {addChevron && lineOneText && lineOneText.length > 0 ?
                                    <i className="fa fa-angle-right" aria-hidden="true"></i> : ''}</h3> : ''}
                        </div>
                    </div>
                </div>
            )
        case THIRTEEN:
            const colThirteenAttributes = [];
            colThirteenAttributes.push('centerTextTileBanner');

            if (backgroundCss && backgroundCss.length > 0)
                colThirteenAttributes.push(Array.isArray(backgroundCss) ? backgroundCss.join(' ') : backgroundCss);
            else
                colThirteenAttributes.push('blueBackgroundColorWithOpacity');

            return (
                <div className={cssStyles.join(' ')} style={styleElement}>
                    <div className="contentCenter">
                        <div className={colThirteenAttributes.join(' ')}>
                            <div className="centeringContainer">
                                <div className="text-center">
                                    {lineOneText && lineOneText.length > 0 ?
                                        <h3 className="whiteColor text-center">{parse(lineOneText)} {(addChevron === undefined || addChevron) && lineOneText && lineOneText.length > 0 ?
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> : ''}</h3> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        case FOURTEEN:
            return (
                <div className={cssStyles.join(' ')} style={styleElement}>
                    <div className="contentCenter">
                        <div className="centerTextTileBanner blueBackgroundColorWithOpacity">
                            <div className="centeringContainer">
                                <div className="h3Container">
                                    {lineOneText && lineOneText.length > 0 ?
                                        <h3 className="whiteColor text-center">{parse(lineOneText)} {(addChevron === undefined || addChevron) && lineOneText && lineOneText.length > 0 ?
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> : ''}</h3> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        case TWENTY:
            return (
                <div className={cssStyles.join(' ')} style={styleElement}>
                    <div className="topTextTileBanner text-center whiteColor blueBackground">
                        <div className="h3Container text-center">
                            <h3 className="floatLeft">
                                <span className="floatLeft messsagesType">Messages</span>
                                <span className="pull-right clearNone newType">New&nbsp;
                                    <span className="numberOutline pull-right clearNone">
                                        <span className="number">{numberOfItems} {addChevron ?
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> : ''}</span>
                                    </span>
                                </span>
                            </h3>
                        </div>
                    </div>
                    <div className="contentCenter">
                        <div className="centerCircle">
                            {parse(lineOneText)}
                        </div>
                    </div>
                </div>
            )
        case TWENTY_ONE:
            return (
                <div className={cssStyles.join(' ')} style={styleElement}>
                    <div className="hidden-xs">
                        <div className="text-center orbitTitle">
                            <p>&nbsp;</p>
                        </div>
                    </div>
                    <div className="centerCircleContainer">
                        <div className="centerCircle">
                            {displayProfileImage ? (profileUrl && profileUrl.length > 0 ?
                                <img alt="Responsive image" className="img-responsive img-circle"
                                     src={profileUrl}/> : imageUrl && imageUrl.length > 0 ?
                                    <img alt="Responsive image" className="img-responsive img-circle"
                                         src={imageUrl}/> : '') : ''}
                        </div>
                    </div>
                    <div className="hidden-xs">
                        <div className="profileBottomText text-right">
                            <h4 className="whiteColor noMarginBottom">&nbsp;</h4>
                        </div>
                    </div>
                </div>
            )
        case TWENTY_TWO:
            return (
                <div className={cssStyles.join(' ')} style={styleElement}>
                    <div>
                        <div className="text-center orbitTitle">
                            <p>{lineOneText && lineOneText.length > 0 ? lineOneText : "My Orbit"}</p>
                        </div>
                    </div>
                    <div className="centerCircleContainer">
                        <div className="centerCircle">
                            {(displayProfileImage ? (profileUrl && profileUrl.length > 0 ?
                                (<img alt="" className="img-responsive img-circle"
                                     src={profileUrl}/>) : imageUrl && imageUrl.length > 0 ?
                                    (<img alt="" className="img-responsive img-circle"
                                     src={imageUrl}/>) : '') : '')}
                        </div>
                    </div>
                    <div className="hidden-xs">
                        <div className="profileBottomText text-right">
                            <h4 className="whiteColor noMarginBottom">&nbsp;</h4>
                        </div>
                    </div>
                    <div className="visible-xs profileBottomText">
                        <div className="text-center whiteColor">
                            <h3>&nbsp;</h3>
                        </div>
                    </div>
                </div>
            )
        case TWENTY_THREE:
            return (
                <div className={cssStyles.join(' ')} style={styleElement}>
                    <div className="hidden-xs">
                        <div className="text-center orbitTitle">
                            <p>{lineOneText && lineOneText.length > 0 ? lineOneText : "My Orbit"}</p>
                        </div>
                    </div>
                    <div className="centerCircleContainer">
                        <div className="indicatorBackground">
                            <span className="number whiteColor">{numberOfItems}</span>
                        </div>
                        <div className="centerCircle">
                            {imageUrl && imageUrl.length > 0 ? <img alt="" className="img-responsive img-circle"
                                             src={imageUrl}/> : ''}
                        </div>
                    </div>
                    <div className="">
                        <div className="profileBottomText text-right">
                            <h4 className="whiteColor noMarginBottom">&nbsp;</h4>
                        </div>
                    </div>
                </div>
            )
        case TWENTY_FIVE:
            const colTwentyFiveAttributes = [];
            colTwentyFiveAttributes.push('col-8');
            colTwentyFiveAttributes.push('typeColorColumn');

            const colBackgroundCss = [];
            colBackgroundCss.push('col-4 imgColumn');
            //colBackgroundCss.push('');

            if (backgroundCss && backgroundCss.length > 0) {
                colTwentyFiveAttributes.push(colTwentyFiveAttributes.push(Array.isArray(backgroundCss) ? backgroundCss.join(' ') : backgroundCss));
                //colBackgroundCss.push(colBackgroundCss.push(Array.isArray(backgroundCss) ? backgroundCss.join(' ') : backgroundCss));
            }

            const extendedElement = {...styleElement, flexGrow: 1, width: '45%', borderBottomLeftRadius: '20px', borderTopLeftRadius: '20px', objectFit: 'cover'};

            const currentBackgroundColor = colTwentyFiveAttributes.find((word) =>
                word.toString().toLowerCase().indexOf('background') !== -1
            )

            if (currentBackgroundColor && currentBackgroundColor.length > 0)
                colBackgroundCss.push(currentBackgroundColor);

            return (
                <div className={cssStyles.join(' ')}>
                    <div className="col-12 tile25Container">
                        <div className={colBackgroundCss.join(' ')}>
                            <img src={imageUrl} style={extendedElement}/>
                            {/*
                            <div className={colBackgroundCss.join(' ')}
                                 style={styleElement}>
                            </div>
                            */}
                        </div>
                            <div className={colTwentyFiveAttributes.join(' ')}>
                                <div className="contentCenter">
                                    <div className="tileJustText">
                                        <h3 className="text-left whiteColor">{parse(lineOneText)} {(addChevron === undefined || addChevron) && lineOneText && lineOneText.length > 0 ?
                                            <i className="fa fa-angle-right" aria-hidden="true"></i> : ''}</h3>
                                    </div>
                                </div>
                            </div>

                    </div>
                </div>
            )
        default:
            return (
                <div className={cssStyles.join(' ')} style={styleElement}>
                    <div className="bottomTextTileBanner text-center blueBackground whiteColor">
                        <div className="h3Container text-center">
                            <h3>Website</h3>
                        </div>
                    </div>
                </div>
            )
    }

}

const constructOuterContainer = ({
     tileType,
     cssStyles,
     styleElement,
     lineOneText,
     lineTwoText,
     numberOfItems,
     profileUrl,
     imageUrl,
     linkUrl,
     tilePageId,
     childClusterId,
     bootstrapClass,
     outerContainerCss,
     cols,
     useAsIndividual,
     displayProfileImage,
     targetType,
     backgroundCss,
     addChevron,
     tileClickable,
     useOuterContainer = true,
     useInChain = false,
     useLeft = false,
     useRight = false
 }) => {

    return (
        !useAsIndividual ? (
            <div className={outerContainerCss.join(' ')}>
                <div className="row">
                    <div className="col-10 offset-lg-1">
                        <div className="row">
                            {
                                cols < 3 ?
                                    <div className={bootstrapClass[0]}>
                                        &nbsp;
                                    </div> :
                                    ''
                            }
                            <div className={bootstrapClass[1]}>
                                <a href={childClusterId ? (CLUSTER_URL + '/cluster/' + childClusterId) : tilePageId ? (TILE_PAGE_URL + '/tilepage/' + tilePageId) : tileClickable && displayProfileImage ? (CLUSTER_URL + '/profile') : linkUrl ? linkUrl : '#'}
                                   target={targetType ? targetType : '_self'}>
                                    {constructHtmlBody({
                                        tileType,
                                        cssStyles,
                                        styleElement,
                                        lineOneText,
                                        lineTwoText,
                                        numberOfItems,
                                        displayProfileImage,
                                        profileUrl,
                                        imageUrl,
                                        targetType,
                                        backgroundCss,
                                        addChevron,
                                        tileClickable
                                    })}
                                </a>
                            </div>
                            {
                                cols < 3 ?
                                    <div className={bootstrapClass[2]}>
                                        &nbsp;
                                    </div> :
                                    ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        ) : (useAsIndividual && !useInChain && useOuterContainer) ?
            <div className={bootstrapClass[1]}>
                <a href={childClusterId ? (CLUSTER_URL + '/cluster/' + childClusterId) : tilePageId ? (TILE_PAGE_URL + '/tilepage/' + tilePageId) : tileClickable && displayProfileImage ? (CLUSTER_URL + '/profile') : linkUrl ? linkUrl : '#'}
                   target={targetType ? targetType : '_self'}>
                    {constructHtmlBody({
                        tileType,
                        cssStyles,
                        styleElement,
                        lineOneText,
                        lineTwoText,
                        numberOfItems,
                        displayProfileImage,
                        profileUrl,
                        imageUrl,
                        targetType,
                        backgroundCss,
                        addChevron,
                        tileClickable
                    })}
                </a>
            </div> : (!useOuterContainer) ?
                <React.Fragment>
                    <a href={childClusterId ? (CLUSTER_URL + '/cluster/' + childClusterId) : tilePageId ? (TILE_PAGE_URL + '/tilepage/' + tilePageId) : tileClickable && displayProfileImage ? (CLUSTER_URL + '/profile') : linkUrl ? linkUrl : '#'}
                       target={targetType ? targetType : '_self'}>
                        {constructHtmlBody({
                            tileType,
                            cssStyles,
                            styleElement,
                            lineOneText,
                            lineTwoText,
                            numberOfItems,
                            displayProfileImage,
                            profileUrl,
                            imageUrl,
                            targetType,
                            backgroundCss,
                            addChevron,
                            tileClickable
                        })}
                    </a>
                </React.Fragment> : (useLeft) ?
                    <div className={'col-6 noPadding variableHeightClusterPaddingLeft'}>
                        <div className={'variableHeightTileClusterTileContainer'}>
                            <a href={childClusterId ? (CLUSTER_URL + '/cluster/' + childClusterId) : tilePageId ? (TILE_PAGE_URL + '/tilepage/' + tilePageId) : tileClickable && displayProfileImage ? (CLUSTER_URL + '/profile') : linkUrl ? linkUrl : '#'}
                               target={targetType ? targetType : '_self'}>
                                {constructHtmlBody({
                                    tileType,
                                    cssStyles,
                                    styleElement,
                                    lineOneText,
                                    lineTwoText,
                                    numberOfItems,
                                    displayProfileImage,
                                    profileUrl,
                                    imageUrl,
                                    targetType,
                                    backgroundCss,
                                    addChevron,
                                    tileClickable
                                })}
                            </a>
                        </div>
                    </div> : (useRight) ?
                        <div className={'col-6 noPadding variableHeightClusterPaddingRight'}>
                            <div className={'variableHeightTileClusterTileContainer'}>
                                <a href={childClusterId ? (CLUSTER_URL + '/cluster/' + childClusterId) : tilePageId ? (TILE_PAGE_URL + '/tilepage/' + tilePageId) : tileClickable && displayProfileImage ? (CLUSTER_URL + '/profile') : linkUrl ? linkUrl : '#'}
                                   target={targetType ? targetType : '_self'}>
                                    {constructHtmlBody({
                                        tileType,
                                        cssStyles,
                                        styleElement,
                                        lineOneText,
                                        lineTwoText,
                                        numberOfItems,
                                        displayProfileImage,
                                        profileUrl,
                                        imageUrl,
                                        targetType,
                                        backgroundCss,
                                        addChevron,
                                        tileClickable
                                    })}
                                </a>
                            </div>
                        </div> : (outerContainerCss) ?
                            <div className={outerContainerCss.join(' ')}>
                                <a href={childClusterId ? (CLUSTER_URL + '/cluster/' + childClusterId) : tilePageId ? (TILE_PAGE_URL + '/tilepage/' + tilePageId) : tileClickable && displayProfileImage ? (CLUSTER_URL + '/profile') : linkUrl ? linkUrl : '#'}
                                   target={targetType ? targetType : '_self'}>
                                    {constructHtmlBody({
                                        tileType,
                                        cssStyles,
                                        styleElement,
                                        lineOneText,
                                        lineTwoText,
                                        numberOfItems,
                                        displayProfileImage,
                                        profileUrl,
                                        imageUrl,
                                        targetType,
                                        backgroundCss,
                                        addChevron,
                                        tileClickable
                                    })}
                                </a>
                            </div> : <div className={'variableHeightTileClusterTileContainer'}>
                                <a href={childClusterId ? (CLUSTER_URL + '/cluster/' + childClusterId) : tilePageId ? (TILE_PAGE_URL + '/tilepage/' + tilePageId) : tileClickable && displayProfileImage ? (CLUSTER_URL + '/profile') : linkUrl ? linkUrl : '#'}
                                   target={targetType ? targetType : '_self'}>
                                    {constructHtmlBody({
                                        tileType,
                                        cssStyles,
                                        styleElement,
                                        lineOneText,
                                        lineTwoText,
                                        numberOfItems,
                                        displayProfileImage,
                                        profileUrl,
                                        imageUrl,
                                        targetType,
                                        backgroundCss,
                                        addChevron,
                                        tileClickable
                                    })}
                                </a>
                            </div>
    )
}

export default Tile;
