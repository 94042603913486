import React from "react";
import Container from "react-bootstrap/Container";
import { Nav } from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { HashLink as Link } from "react-router-hash-link";
import YouTube from "react-youtube";

const LivingWellCenter1_2 = () => {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div>
      <div className="w-100 legosNewBuild livingWellCenterHeader">
        <section className="mb-0">
          {/* L6 Background image header bar */}
          <div className="lego l006 legoMargin5">
            <div
              className="backgroundImage"
              style={{
                backgroundImage:
                  "url(" +
                  "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fb6924af46ed2140ef7f891c19cb6680.png" +
                  ")",
              }}
            >
              <div
                className="transparentBarTypeContainer"
                style={{
                  backgroundColor: "#376712\n",
                }}
              >
                &nbsp;&nbsp;
              </div>
              <h2 className="l6SubheadWithBackground">Our Promise to You</h2>
            </div>
          </div>
          {/* End L6 Background image header bar */}
        </section>
        <Container>
          <div className="w-100">
            <div className="col-12 m-auto">
              <div className="whiteBackground floatLeft clearLeft legosNewBuild livingWellCenterHeader">
                <section>
                  {/* L12B? Content 1 side 70% width */}
                  <div className="lego l012B p-0">
                    <div className="contentLeftSide">
                      {/* L11 Image */}
                      <div
                        className="lego l011 legoMargin3"
                        style={{ justifyContent: "flex-end" }}
                      >
                        <img
                          className="imageW100 noBorderRadius maxWidth300"
                          src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/9162560e08f725f61b013117564ddfe3.jpeg"
                        />
                      </div>
                      {/* End L11 Image */}
                    </div>
                    <div className="contentRightSide">
                      <div className="legoMargin5">
                        <p>
                          “At the{" "}
                          <span className="boldText">
                            Washington University Living Well Center
                          </span>{" "}
                          we're changing the way healthcare is delivered. With a
                          multidisciplinary team of experts, we will provide you
                          with comprehensive care and treat the root cause of
                          your problem — not just the symptoms — with the
                          principles of lifestyle medicine. 
                        </p>
                        <p>
                          We offer you your ideal range of coordinated services
                          to treat the whole you, including nutritional
                          counseling, physical therapy, behavioral health
                          counseling, and more. 
                        </p>
                        <p>
                          And, we will make sure those services are available in
                          ways that can fit your health and wellness needs, your
                          lifestyle, your schedule and, in cases where insurance
                          may not cover all services, your budget.”
                          <br />
                        </p>
                        <p>
                          <span className="boldSignature">
                            {" "}
                            Dr. Devyani Hunt
                          </span>
                          <br />
                          <span className="boldSignatureTitle">
                            {" "}
                            Medical Director, Living Well Center
                          </span>
                          {" "}
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* End L12B? Content 1 side 70% width */}
                </section>
              </div>
            </div>
          </div>
        </Container>

        <section className="mb-0">
          {/* L88A Bold rectangle Footer Box  */}
          <div className="lego l088A">
            <div className="contentContainer">
              <p>
                Experience has shown that engaged participants in our
                <br />
                programs can expect to see health improvements within <br />
                <span className="highlightedText">90-120 days. </span>
              </p>
              {/* <p style={{ fontWeight: 400 }}>90-120 days. </p>*/}
            </div>
          </div>

          {/* Ends Lego L88A Bold rectangle Footer Box  */}

          {/* L88B Bold Content rectangle Footer Box */}

          <div className="lego l088B legoMargin5">
            <div className="contentContainer">
              <p>
                <span className="highlightedText">Results often include:</span>
                <br />
                less pain
                <br />
                improved strength and stamina <br />
                weight loss,
                <br />
                controlled diabetes and/or lower blood pressure. 
              </p>
              {/* <p style={{ fontWeight: 400 }}>90-120 days. </p>*/}
            </div>
          </div>

          {/* Ends L88B Bold rectangle Footer Box  */}
        </section>

        <Container>
          <div className="w-100">
            <div className="col-12 m-auto">
              <div className="whiteBackground floatLeft clearLeft legosNewBuild livingWellCenterHeader">
                <section>
                  <div className="col-12 backToTop">
                    <div className="w-100">
                      <p>
                        <a
                          className="specialUseBackToTop"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          BACK TO TOP{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default LivingWellCenter1_2;
